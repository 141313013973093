import { v4 as uuidv4 } from 'uuid'
import React from 'react'
import PropTypes from 'prop-types'
import * as ratingStyles from './rating.module.scss'

const Rating = ({ rating, large = false }) => {
    const icons = []

    const width = large ? '37.5' : '30'
    const height = large ? '45' : '35'

    const convertedRating = rating / 2

    const getFullIcon = (color) => {
        return (
            <svg
                key={uuidv4()}
                className={ratingStyles.ratingIcon}
                width={width}
                height={height}
                viewBox="0 0 20 25"
                fill={color}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M20 24.6447H18.1428C18.1428 22.6061 17.2722 20.7001 15.74 19.4094L15.5659 19.2646C13.0354 17.1174 11.5728 13.9448 11.5728 10.5551V2.13515C11.5728 2.02658 11.48 1.93008 11.3755 1.93008H8.62449C8.50842 1.93008 8.42716 2.02658 8.42716 2.13515V10.5551C8.42716 13.9448 6.9762 17.1174 4.43413 19.2646L4.26001 19.4094C2.7278 20.7001 1.85723 22.6061 1.85723 24.6447H0C0 22.027 1.12594 19.5662 3.08764 17.9136L3.26175 17.7688C5.36274 15.9955 6.55833 13.3658 6.55833 10.5672V2.13515C6.55833 0.952977 7.48694 0 8.61288 0H11.3755C12.5131 0 13.4301 0.952977 13.4301 2.13515V10.5551C13.4301 13.3658 14.6373 15.9835 16.7266 17.7567L16.9008 17.9015C18.8741 19.5662 20 22.027 20 24.6447Z" />
            </svg>
        )
    }

    const getHalfIcon = (color) => {
        return (
            <svg
                key={uuidv4()}
                x="0px"
                y="0px"
                width={width}
                height={height}
                viewBox="0 0 201 247"
            >
                <path
                    fill="#EAE8E7"
                    d="M100.5,0h13.8c11.4,0,20.5,9.5,20.5,21.4v84.2c0,28.1,12.1,54.3,33,72l1.7,1.4c19.7,16.6,31,41.3,31,67.4h-18.6c0-20.4-8.7-39.4-24-52.4l-1.7-1.4c-25.3-21.5-39.9-53.2-39.9-87.1V21.4c0-1.1-0.9-2.1-2-2.1h-13.8"
                />
                <path
                    fill={color}
                    d="M100.5,19.3H86.7c-1.2,0-2,1-2,2.1v84.2c0,33.9-14.5,65.6-39.9,87.1l-1.7,1.4c-15.3,12.9-24,32-24,52.4H0.5c0-26.2,11.3-50.8,30.9-67.3l1.7-1.4c21-17.7,33-44,33-72V21.4C66.1,9.5,75.4,0,86.6,0h13.9"
                />
            </svg>
        )
    }

    // Rating is always 10 but shown out of 5
    for (let i = 1; i <= 5; i++) {
        if (i <= convertedRating) {
            icons.push(getFullIcon('#1b4942'))
        } else {
            if (i - 0.5 === convertedRating) {
                icons.push(getHalfIcon('#1b4942'))
            } else {
                icons.push(getFullIcon('#EAE8E7'))
            }
        }
    }
    return (
        <div
            className={ratingStyles.ratingWrapper}
            title={`${convertedRating} av 5 i betyg`}
        >
            <h4>Betyg av Vinologik</h4>
            {icons}
        </div>
    )
}

Rating.propTypes = {
    rating: PropTypes.number.isRequired,
    large: PropTypes.bool,
}

export default Rating
