import React from 'react'
import PropTypes from 'prop-types'
import * as filterStyles from './filter.module.scss'

const Filter = ({ filterData, selectedFilter, setSelectedFilter }) => {
    const handleWineTypeChange = (event) => {
        const selectedWineType = event.target.value
        setSelectedFilter({
            ...selectedFilter,
            selectedWineType,
        })
    }

    const handleCountryChange = (event) => {
        const selectedCountry = event.target.value
        setSelectedFilter({
            ...selectedFilter,
            selectedCountry,
        })
    }

    // const handleGrapeChange = (event) => {
    //     const selectedGrape = event.target.value
    //     setSelectedFilter({
    //         ...selectedFilter,
    //         selectedGrape,
    //     })
    // }

    const handleOnlyWinesWithScoreChange = (event) => {
        const selectedWinesWithScoreOnly = event.target.checked
        setSelectedFilter({
            ...selectedFilter,
            selectedWinesWithScoreOnly,
        })
    }

    const handleOnlyWinesWithAffordableRatingChange = (event) => {
        const selectedAffordableWinesOnly = event.target.checked
        setSelectedFilter({
            ...selectedFilter,
            selectedAffordableWinesOnly,
        })
    }

    const handleOnlyWinesWithHighScoreChange = (event) => {
        const selectedHighscoreOnly = event.target.checked
        setSelectedFilter({
            ...selectedFilter,
            selectedHighscoreOnly,
        })
    }

    return (
        <div className={filterStyles.filterWrapper}>
            <h3>Filtrera på</h3>

            <select
                onChange={handleWineTypeChange}
                value={selectedFilter.selectedWineType}
            >
                <option value="">Vintyp</option>
                {filterData.allWineTypes.map((wineType, index) => (
                    <option key={wineType + index} value={wineType}>
                        {wineType}
                    </option>
                ))}
            </select>

            <select
                onChange={handleCountryChange}
                value={selectedFilter.selectedCountry}
            >
                <option value="">Land</option>
                {filterData.allCountries.map((country, index) => (
                    <option key={country + index} value={country}>
                        {country}
                    </option>
                ))}
            </select>
            {/* <select
                onChange={handleGrapeChange}
                value={selectedFilter.selectedCountry}
            >
                <option value="">Druva</option>
                {filterData.allGrapes.map((grape, index) => (
                    <option key={grape + index} value={grape}>
                        {grape}
                    </option>
                ))}
            </select> */}
            <div className={filterStyles.checkboxWrapper}>
                <label className="switch">
                    <input
                        type="checkbox"
                        id="onlyWinesWithScore"
                        name="onlyWinesWithScore"
                        value="onlyWinesWithScore"
                        onChange={handleOnlyWinesWithScoreChange}
                    />
                    <span className="slider round"></span>
                </label>
                <label
                    className={filterStyles.headline}
                    htmlFor="onlyWinesWithScore"
                >
                    Enbart recenserade viner
                </label>
            </div>
            <div className={filterStyles.checkboxWrapper}>
                <label className="switch">
                    <input
                        type="checkbox"
                        id="onlyWinesWithAffordableRating"
                        name="onlyWinesWithAffordableRating"
                        value="onlyWinesWithAffordableRating"
                        onChange={handleOnlyWinesWithAffordableRatingChange}
                    />
                    <span className="slider round"></span>
                </label>
                <label
                    className={filterStyles.headline}
                    htmlFor="onlyWinesWithAffordableRating"
                >
                    Enbart prisvärda viner
                </label>
            </div>
            <div className={filterStyles.checkboxWrapper}>
                <label className="switch">
                    <input
                        type="checkbox"
                        id="onlyWinesWithHighScore"
                        name="onlyWinesWithHighScore"
                        value="onlyWinesWithHighScore"
                        onChange={handleOnlyWinesWithHighScoreChange}
                    />
                    <span className="slider round"></span>
                </label>
                <label
                    className={filterStyles.headline}
                    htmlFor="onlyWinesWithHighScore"
                >
                    Enbart viner med höga betyg
                </label>
            </div>
        </div>
    )
}

Filter.propTypes = {
    filterData: PropTypes.object.isRequired,
    selectedFilter: PropTypes.object.isRequired,
    setSelectedFilter: PropTypes.func.isRequired,
}

export default Filter
