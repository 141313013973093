import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Flag from 'react-flagkit'
import Rating from './rating'
import * as wineListStyles from './wineList.module.scss'
import classNames from 'classnames'
import Banner from './common/banner'

const WineList = ({ wines }) => {
    const sortedWines = wines.sort((a, b) => {
        if (a.comment && !b.comment) return -1
        if (b.comment && !a.comment) return 1
        return 0
    })

    return (
        <div className={wineListStyles.wineList}>
            {sortedWines.map((wine) => {
                const wineWrapperClasses = classNames({
                    [wineListStyles.wineWrapper]: true,
                    [wineListStyles.wineWrapperWithComment]: wine.comment,
                })
                return (
                    <Link
                        className={wineWrapperClasses}
                        key={wine.id}
                        to={wine.slug}
                    >
                        <div className={wineListStyles.mainContentWrapper}>
                            <div className={wineListStyles.imageWrapper}>
                                {wine.highScore && (
                                    <Banner
                                        text={'Högt betyg!'}
                                        farLeft={true}
                                        awardIcon={true}
                                    />
                                )}
                                {wine.highQpr && (
                                    <Banner text={'Prisvärd!'} farLeft={true} />
                                )}
                                <GatsbyImage
                                    image={wine.smallImage.gatsbyImageData}
                                    alt={wine.title}
                                />
                            </div>
                            <div className={wineListStyles.wineIntroWrapper}>
                                <h5>
                                    <strong>{wine.wineType}</strong>
                                    {wine.packagingAndVolume && (
                                        <> - {wine.packagingAndVolume}</>
                                    )}
                                </h5>
                                <h3>
                                    {wine.producer} <br />
                                </h3>
                                <h4>
                                    {wine.name} {wine.vintage}
                                </h4>
                                <h4>
                                    {wine.price} kr
                                    {wine.alcoholPercentage && (
                                        <> - {wine.alcoholPercentage}%</>
                                    )}
                                </h4>
                                {wine.isStandardRange && (
                                    <h5 className="fineprint">
                                        Systembolagets fasta sortiment
                                    </h5>
                                )}
                                <h5 className={wineListStyles.originWrapper}>
                                    <Flag
                                        className={wineListStyles.flag}
                                        country={wine.countryCode}
                                    />{' '}
                                    <span>
                                        <strong>{wine.country}</strong>
                                        {wine.region ? `, ${wine.region}` : ''}
                                        {wine.subregion
                                            ? `, ${wine.subregion}`
                                            : ''}
                                    </span>
                                </h5>
                            </div>
                        </div>
                        {wine.comment && (
                            <div className={wineListStyles.wineCommentWrapper}>
                                <h4>“{wine.comment.trim()}”</h4>
                                {wine.rating && (
                                    <Rating
                                        rating={wine.rating}
                                        large={false}
                                    />
                                )}
                            </div>
                        )}
                    </Link>
                )
            })}
        </div>
    )
}

export default WineList
