import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import SectionContainer from '../components/layout/sectionContainer'
import * as vinerStyling from './viner.module.scss'
import * as pageStyling from './pageStyling.module.scss'
import WineList from '../components/wineList'
import Filter from '../components/common/filter'
import { getUniqueValuesFromArray } from '../utils/helpers'

const WinesPage = () => {
    const data = useStaticQuery(graphql`
        {
            allContentfulWine {
                nodes {
                    ...WineTeaserFields
                }
            }
        }
    `)

    const defaultFilter = {
        selectedWineType: '',
        selectedCountry: '',
        selectedGrape: '',
        selectedHighscoreOnly: false,
        selectedWinesWithScoreOnly: false,
        selectedAffordableWinesOnly: false,
        searchTerm: '',
    }

    const [allWinesRaw, setAllWinesRaw] = useState(data.allContentfulWine.nodes)
    const [allFilteredWines, setAllFilteredWines] = useState(allWinesRaw)
    const [selectedFilter, setSelectedFilter] = useState(defaultFilter)

    const filterOutWines = () => {
        const filteredOutWines = allWinesRaw.filter((wine) => {
            if (selectedFilter.selectedWineType) {
                if (wine.wineType !== selectedFilter.selectedWineType) {
                    return false
                }
            }
            if (selectedFilter.selectedCountry) {
                if (wine.country !== selectedFilter.selectedCountry) {
                    return false
                }
            }
            if (selectedFilter.selectedWinesWithScoreOnly) {
                if (wine.rating === null || wine.comment === null) {
                    return false
                }
            }
            if (selectedFilter.selectedHighscoreOnly) {
                if (!wine.highScore) {
                    return false
                }
            }
            if (selectedFilter.selectedAffordableWinesOnly) {
                if (!wine.highQpr) {
                    return false
                }
            }
            return true
        })
        return filteredOutWines
    }

    useEffect(() => {
        setAllFilteredWines(filterOutWines())
    }, [selectedFilter])

    const allCountries = getUniqueValuesFromArray('country', allWinesRaw)

    const allWineTypes = getUniqueValuesFromArray('wineType', allWinesRaw)

    const allGrapes = getUniqueValuesFromArray('grapes', allWinesRaw)

    const filterData = {
        allCountries,
        allWineTypes,
        allGrapes,
    }

    const wines = allFilteredWines.reduce((listOfWines, wine) => {
        // Get first letter of name of current element
        const group = wine.producer[0]
        // If there is no property in accumulator with this letter create it
        if (!listOfWines[group])
            listOfWines[group] = { group, children: [wine] }
        // If there is push current element to children array for that letter
        else listOfWines[group].children.push(wine)
        // Return accumulator

        return listOfWines
    }, {})

    const allWines = Object.values(wines)

    allWines.sort((a, b) => a.group.localeCompare(b.group))

    return (
        <>
            <GatsbySeo
                title="Viner"
                description="Nyfiken på att djupdyka ner i specifika viner? Här hittar du alla viner som är omnämnda på Vinologik och kan läsa mer om var och en!"
            />
            <Hero
                heading="Viner"
                body="Nyfiken på att djupdyka ner i specifika viner? Här hittar du alla viner som är omnämnda på Vinologik och kan läsa mer om var och en!"
            />
            <div className={pageStyling.defaultWrapper}>
                <SectionContainer
                    title="Alla viner på Vinologik"
                    subtitle="Här kan du läsa mer om specifika viner och är du inloggad kan du även kommentera."
                    asColumn={true}
                >
                    <Filter
                        filterData={filterData}
                        selectedFilter={selectedFilter}
                        setSelectedFilter={setSelectedFilter}
                    />
                    {allWines && (
                        <p>
                            Visar {allFilteredWines.length} av{' '}
                            {allWinesRaw.length} viner.
                        </p>
                    )}
                    {!allWines.length && (
                        <div>
                            <h4>
                                Hittade tyvärr inget, testa att söka efter en
                                producent, land, region eller druva.
                            </h4>
                        </div>
                    )}
                    {allWines.map((wine) => (
                        <div
                            className={vinerStyling.groupWrapper}
                            key={wine.group}
                        >
                            <h1 className={vinerStyling.groupHeadline}>
                                {wine.group}
                            </h1>
                            <WineList wines={wine.children} />
                        </div>
                    ))}
                </SectionContainer>
            </div>
        </>
    )
}

export default WinesPage
