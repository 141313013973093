import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as bannerStyles from './banner.module.scss'

const Banner = ({ text, large, farLeft, mini }) => {
    const bannerClasses = classNames({
        [bannerStyles.banner]: true,
        [bannerStyles.bannerLarge]: large,
        [bannerStyles.bannerMini]: mini,
        [bannerStyles.bannerFarLeft]: farLeft,
    })
    return <span className={bannerClasses}>{text}</span>
}

Banner.propTypes = {
    text: PropTypes.string.isRequired,
    large: PropTypes.bool,
    farLeft: PropTypes.bool,
    mini: PropTypes.bool,
}

export default Banner
